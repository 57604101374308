.PasswordModalContentContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
}

.PasswordPaperRootContainer {
    width: 35%;
    height: 220px;
}

.PasswordModalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialogActionContainer {
    display: flex !important;
    justify-content: center !important;
}

.dialogContentContainer {
    display: flex;
    flex-direction: column;
}