.pageTitleBar {
    height: 30px;
    width: auto;
    background-color: #fb803a;
    display: grid;
    grid-template-columns: 4fr 1fr;
    padding: 20px;
    margin-top: 2px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 10px 10px 5px grey;
    border-radius: 5px;
}

@media (max-width: 905px) {
    .pageTitleBar {
        grid-template-columns: 1fr;
        height: 60px;
    }
}

@media (max-width: 500px) {
    .pageTitleBar {
        height: 100px;
    }
}

@media (max-width: 366px) {
    .pageTitleBar {
        height: 120px;
    }
}