.AboutContainer {
    height: 450px;
    .textContainer {
        padding: 30px;
    }
}

@media(max-width:468px) {
    .AboutContainer {
        height: 750px;
    }
}

@media(max-width:300px) {
    .AboutContainer {
        height: 850px;
    }
}