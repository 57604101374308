.ProfileCardContnentContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

@media(max-width:555px) {
    .CardHeaderContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }
    .CardHeaderActionContainer {
        width: 100%;
        padding-top: 10px;
    }
}