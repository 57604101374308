.ProfessionalSignInstepContainer {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 130px;
    padding-right: 130px;
    padding-left: 130px;
    margin-bottom: 50px;
    * {
        margin-bottom: 10px;
    }
}

@media (max-width: 425px) {
    .ProfessionalSignInstepContainer {
        padding-bottom: 20px;
        padding-right: 20px;
        padding-left: 20px;
    }
}