.PopoverContainer {
    width: 100%;
}

.PopoverListContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ListItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}