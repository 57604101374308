.RdvInformationStepContainer {
    display: flex;
    flex-direction: column;
    * {
        margin-bottom: 10px;
    }
}

.RdvInformationConditionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
