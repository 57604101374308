.landingBackgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    height: 700px;
    width: 100%;
}

.backgroundImageMask {
    top: 60;
    left: 0;
    position: absolute;
    z-index: 2;
    height: 700px;
    width: 100%;
    background-color: #3ab5fb;
    opacity: 40%;
}

.landingFirstPart {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 30;
    margin: 0;
}

.landingSecondPart {
    min-height: 800px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #ebe5dc;
    padding-top: 50px;
    padding-bottom: 50px;
}

.landingShowMoreBtn {
    position: absolute;
    z-index: 10000;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 5%;
}