.FootRoot {
    background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
    border-radius: 3;
    border: 0;
    padding: 0;
    margin: 0;
    color: white;
    height: 60;
    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);
    bottom: 0%;
}
